import React from "react"
import Image1 from "./Image1"
import Image2 from "./Image2"
import Image3 from "./Image3"

const Images = ({ block, ...props }) => {
  switch (block.imageS.length) {
    case 1:
      return <Image1 image={block.imageS[0].image.gatsbyImageData} />
    case 2:
      return (
        <Image2
          image01={block.imageS[0].image.gatsbyImageData}
          image02={block.imageS[1].image.gatsbyImageData}
        />
      )
    case 3:
      return (
        <Image3
          image01={block.imageS[0].image.gatsbyImageData}
          image02={block.imageS[1].image.gatsbyImageData}
          image03={block.imageS[2].image.gatsbyImageData}
        />
      )
    default:
      return null
  }
}

Images.propTypes = {}

export default Images
