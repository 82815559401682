import React, { useEffect } from "react"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.4,
    },
  },
}

const imgLeftVariants = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
}

const imgRightVariants = {
  hidden: {
    opacity: 0,
    x: 20,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
}

const Image2 = ({ image01, image02, texte01, texte02 }) => {
  const controls = useAnimation()
  const { ref, inView } = useInView({
    threshold: 0.75,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [inView, controls])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="grid grid-cols-12 max-w-[1920px] mx-auto mt-6 lg:mt-12"
    >
      <motion.div className="col-span-7" variants={imgLeftVariants}>
        <GatsbyImage
          className="object-cover w-full h-full"
          image={image01}
          alt={texte01}
        />
      </motion.div>
      <motion.div className="col-span-5" variants={imgRightVariants}>
        <GatsbyImage
          className="object-cover w-full h-full"
          image={image02}
          alt={texte02}
        />
      </motion.div>
    </motion.div>
  )
}

Image2.propTypes = {}

export default Image2
