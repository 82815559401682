import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

const Image3 = ({
  image01,
  image02,
  image03,
  alt01,
  alt02,
  alt03,
  ...props
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  const containerVariants = {
    hidden: { opacity: 0.4 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  }

  const imgVariants = {
    hidden: { y: 40, opacity: 0.3 },
    visible: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [inView, controls])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="image-overlay container mx-auto grid grid-cols-12 gap-5 grid-rows-3 mt-6 lg:mt-12"
    >
      <motion.div
        className="col-start-3 col-span-10 row-start-1 row-end-3"
        variants={imgVariants}
      >
        <GatsbyImage image={image01} alt={alt01} className="w-full" />
      </motion.div>
      <motion.div
        variants={imgVariants}
        className="col-start-1 col-span-5 row-start-2 row-end-4"
      >
        <GatsbyImage image={image02} alt={alt02} className="w-full" />
      </motion.div>
      <motion.div variants={imgVariants} className="col-span-7">
        <GatsbyImage image={image03} alt={alt03} className="w-full" />
      </motion.div>
    </motion.div>
  )
}

Image3.propTypes = {}

export default Image3
